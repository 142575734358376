/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  categoryFilter,
  searchKeyword,
  sortFilter,
} from "../../../../../actions/common";
import { Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export function AsideMenuList({ layoutProps }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [catDisplayName, setcatDisplayName] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [clearSearchflag, setclearSearchflag] = useState(false);
  const [selctedsortValue, setselctedsortValue] = useState("new_to_old");
  const dispatch = useDispatch();
  const history = useHistory();

  // const headerMenu = [
  //   '/my-orders',
  //   '/marketing-material',
  //   '/my-account',
  //   '/help',
  //   '/return',
  //   '/trunkey',
  //   '/upgrade-save'
  // ]

  const renderTree = (nodes) =>
    Array.isArray(nodes) ? (
      nodes.map((node, index) => {
        return (
          <li
            className={`menu-item ${
              Array.isArray(node.children) && node.children.length > 0
                ? "menu-item-submenu"
                : ""
            }`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            key={index}
          >
            <a className="menu-link menu-toggle" href={void 0}>
              <span
                className="menu-text ml-8"
                onClick={(e) => {
                  selected_category(e, node);
                }}
              >
                {node.title}
              </span>
              {Array.isArray(node.children) && node.children.length > 0 && (
                <i className="menu-arrow"></i>
              )}
            </a>

            {Array.isArray(node.children) && node.children.length > 0 ? (
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {node.children?.map((child_node) => renderTree(child_node))}
                </ul>
              </div>
            ) : null}
          </li>
        );
      })
    ) : (
      <li
        className={`menu-item ${
          Array.isArray(nodes.children) && nodes.children.length > 0
            ? "menu-item-submenu"
            : ""
        }`}
        aria-haspopup="true"
        data-menu-toggle="hover"
        key={nodes.key}
      >
        <a className="menu-link menu-toggle" href={void 0}>
          <span
            className="menu-text ml-8"
            onClick={(e) => {
              selected_category(e, nodes);
            }}
          >
            {nodes.title}
          </span>
          {Array.isArray(nodes.children) && nodes.children.length > 0 && (
            <i className="menu-arrow"></i>
          )}
        </a>

        {Array.isArray(nodes.children) && nodes.children.length > 0 ? (
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {nodes.children?.map((child_node) => renderTree(child_node))}
            </ul>
          </div>
        ) : null}
      </li>
    );

  function addQueryParamsToURL(newParams) {
    // Get the current URL
    const currentURL = window.location.href;

    // Parse the URL to extract the existing query parameters
    const url = new URL(currentURL);

    // Add or update the new query parameters
    for (const param in newParams) {
      url.searchParams.set(param, newParams[param]);
    }

    const pathAndQuery = "/all-inventory" + url.search;

    // Build the updated URL with the combined parameters
    const updatedURL = pathAndQuery.toString();

    // Use history.pushState to update the browser URL
    history.push(updatedURL);
  }

  const { categories, searchKey, sortValue } = useSelector(
    (state) => ({
      categories: state.common && state.common?.categoryID,
      searchKey: state.common && state.common?.searchKeyword,
      sortValue: state.common && state.common?.sortFilter,
    }),
    shallowEqual
  );

  // const getRedirectionURL = (category,search,sort) => {
  //   return `/all-inventory?category=${category || ''}search=${search || ''}sort=${sort || 'new_to_old'}`
  // }

  useEffect(() => {
    if (categories === null || searchKey === null || sortValue === null) {
      if (categories === null) {
        setcatDisplayName(false);
        setSelectedCategory(null);
      }

      if (searchKey === null) {
        setclearSearchflag(false);
        setsearchValue("");
      }

      if (sortValue === "new_to_old") {
        setselctedsortValue("new_to_old");
      }

      // getRedirectionURL(categories,searchKey,sortValue)
    }
  }, [categories, searchKey, sortValue]);

  useEffect(() => {
    if (categories !== null) {
      dispatch(searchKeyword({ searchKeyword: null }));
      setclearSearchflag(false);
      setsearchValue("");
      // getRedirectionURL(categories,'',sortValue)
    } else {
      setcatDisplayName(false);
      setSelectedCategory(null);
      // getRedirectionURL(categories,searchKey,sortValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (searchKey !== null) {
      dispatch(categoryFilter({ categoryId: null }));
      setcatDisplayName(false);
      setSelectedCategory(null);
      // getRedirectionURL('',searchKey,sortValue)
    } else {
      setclearSearchflag(false);
      setsearchValue("");
      // getRedirectionURL(categories,searchKey,sortValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const selected_category = (event, node) => {
    dispatch(categoryFilter({ categoryId: node.key }));
    setcatDisplayName(true);
    setSelectedCategory(node.title);

    if (window.location.pathname !== "/all-inventory") {
      if (window.location.pathname !== "/my-inventory") {
        //   history.push(`/all-inventory?filter=true`);
        const newQueryParams = {
          filter: true,
        };

        addQueryParamsToURL(newQueryParams);
      }
    }
    // getRedirectionURL(node.key,searchKey,sortValue)
  };

  const clear_category = () => {
    dispatch(categoryFilter({ categoryId: null }));
    setcatDisplayName(false);
    setSelectedCategory(null);
    // getRedirectionURL(null,searchKey,sortValue)
  };

  const searchVal = () => {
    if (searchValue !== "") {
      dispatch(searchKeyword({ searchKeyword: searchValue.trim() }));
      setclearSearchflag(true);
      // getRedirectionURL(categories,searchValue.trim(),sortValue)
    }
  };

  const clearSearch = () => {
    dispatch(searchKeyword({ searchKeyword: null }));
    setclearSearchflag(false);
    setsearchValue("");
    // getRedirectionURL(categories,null,sortValue)
  };

  const handsortingChange = (e) => {
    dispatch(sortFilter({ sortFilter: e.target.value }));
    setselctedsortValue(e.target.value);
    // getRedirectionURL(categories,searchKey,e.target.value)
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      searchVal();
    }
  };

  // useEffect(() => {
  //   if(headerMenu.includes(history?.location?.pathname) && (categories !== null || searchKey !== null || sortValue !== null )){

  //     // var redirectionURL = getRedirectionURL(categories,searchKey,sortValue)
  //     var redirectionURL = '/all-inventory?shop=myonlineteststoreforapp.myshopify.com&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbXlvbmxpbmV0ZXN0c3RvcmVmb3JhcHA&bypass=22868300104343534024&redirect=true'
  //     history.push(redirectionURL)
  //   }

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[categories, history.location.pathname, searchKey, sortValue])

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Categories */}
        {catDisplayName && (
          <li
            className="menu-section"
            style={{ margin: "0px", justifyContent: "center" }}
          >
            <Chip
              variant="outlined"
              label={selectedCategory.replace(/ *\([^)]*\) */g, "")}
              className="mr-2 mb-2 font-size-h6 text-center text-uppercase"
              onClick={clear_category}
              onDelete={clear_category}
            />
          </li>
        )}
        {/* begin::section */}
        <li className="menu-section" style={{ margin: "0px" }}>
          <h3 className="menu-text font-size-h4">Categories</h3>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {renderTree(layoutProps.categoryTree)}

        {/* Applications */}
        {/* begin::section */}

        <li className="menu-section ">
          <h4 className="menu-text font-size-h4">Search</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className="menu-section"
          style={{ margin: "5px", padding: "0 15px" }}
        >
          <div className="form-group row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-describedby="basic-addon2"
                  onChange={(e) => setsearchValue(e.target.value)}
                  value={searchValue}
                  onKeyDown={handleKeypress}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    onClick={searchVal}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="flaticon2-search-1 icon-md"></i>
                  </span>
                </div>
              </div>
              {clearSearchflag && (
                <a
                  href={void 0}
                  className="btn submit"
                  style={{ float: "right", fontSize: "12px" }}
                  id="clear_search"
                  onClick={clearSearch}
                >
                  Clear &nbsp;
                  <i className="quick-search-close ki ki-close icon-sm text-muted"></i>
                </a>
              )}
            </div>
          </div>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text font-size-h4">Filter By</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li className="" style={{ margin: "5px", padding: "0 15px" }}>
          <div className="form-group row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <select
                id="filter_by"
                name="filter_by"
                className="form-control selectpicker"
                value={selctedsortValue}
                onChange={(e) => {
                  handsortingChange(e);
                }}
              >
                <option value="new_to_old">Newest to Oldest</option>
                <option value="old_to_new">Oldest to Newest</option>
                <option value="low_to_high">Cost: Low to High</option>
                <option value="high_to_low">Cost: High to Low</option>
                <option value="qty_low_to_high">Qty: Low to High</option>
                <option value="qty_high_to_low">Qty: High to Low</option>
              </select>
            </div>
          </div>
        </li>

        {/* <div className="form-group row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Link to={{ pathname: 'upgrade-save' }}><img src={toAbsoluteUrl('assets/images/annualplan.png')} alt="Upgrade & Save" className="img-fluid pr-5 pl-5" /></Link>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Link to={{ pathname: "https://www.myonlinefashionstore.com/products/pre-programmed-pre-designed-pre-loaded-with-our-items-ready-to-launch-website" }} target="_blank|_self|_parent|_top|app-iframe"><img src={toAbsoluteUrl('assets/images/99PACKAGE.jpg')} alt="99 Package" className="img-fluid pr-5 pl-5" /></Link>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Link to={{ pathname: "https://www.myonlinefashionstore.com/pages/custom-packaging" }} target="_blank|_self|_parent|_top|app-iframe"><img src={toAbsoluteUrl('assets/images/SQUARELAYOUT.jpg')} alt="SQUARELAYOUT" className="img-fluid pr-5 pl-5" /></Link>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Carousel>
              <div> <img src={toAbsoluteUrl('assets/images/SOLDmar2021.jpg')} alt="SOLDmar2021.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS2.jpg')} alt="HELPFULTIPS2.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS3.jpg')} alt="HELPFULTIPS3.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS4.jpg')} alt="HELPFULTIPS4.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS5.jpg')} alt="HELPFULTIPS5.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS6.jpg')} alt="HELPFULTIPS6.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS7.jpg')} alt="HELPFULTIPS7.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS8.jpg')} alt="HELPFULTIPS8.jpg" className="img-fluid pr-5 pl-5" /> </div>
              <div> <img src={toAbsoluteUrl('assets/images/HELPFULTIPS9.jpg')} alt="HELPFULTIPS9.jpg" className="img-fluid pr-5 pl-5" /> </div>
            </Carousel>
          </div>
        </div> */}
        {/* end:: section */}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
